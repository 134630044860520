/* FILEPATH: /Users/billybaber/Desktop/akash-decision-tool/src/styles/AuthenticationScreen.module.css */

/* Import MUI styles */

/* Define styles for the AuthenticationScreen component */
.container {
  display: flex;
  flex-direction: column;
  max-width: 800px; /* Or whatever maximum width you prefer */
  margin: 0 auto; /* 0 is for top and bottom margin. 'auto' for left and right margins */
  padding: 20px;
  gap: 20px; /* space between form items */
}

form {
  width: 100%;
  max-width: 400px; /* or whatever width you prefer */
  display: flex;
  flex-direction: column;
  gap: 20px; /* space between form items */
}
